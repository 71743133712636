




































































































































































































































































































































































































































































































































import Base from '@/mixins/Base.vue';
import { IClient } from '@/interfaces/client';
import {
    IJob, IJobsResponse, Status,
} from '@/interfaces/job';
import { IPagination } from '@/interfaces/pagination';

const component = Base.extend({
    beforeRouteUpdate(to: any, from: any, next: Function) {
        this.getJobs();
        next();
    },
    data() {
        return {
            openedRows: [] as Array<number>,
            params: {
                name: '',
                sortBy: 'id',
                orderBy: 'desc',
                status: this.$route.params.status || '' as Status,
                client: undefined as any | IClient,
                page: 1,
            },
            jobs: {
                data: [] as Array<IJob>,
                meta: {} as IPagination | any,
            } as IJobsResponse,
            status: ['pending', 'done', 'canceled'],
        };
    },
    mounted() {
        this.getJobs();
    },
    methods: {
        toggleRow(row: IJob) {
            const index = this.openedRows.indexOf(row.id);
            if (index === -1) {
                this.openedRows.push(row.id);
            } else {
                this.$delete(this.openedRows, index);
            }
        },
        getJobs(page: number = 1) : void{
            this.params.page = page;

            this.get<IJobsResponse>('jobs', {
                params: {
                    ...this.params,
                    client_id: this.params.client ? this.params.client.id : null,
                },
            }).then(({ data }) => {
                this.jobs = data;
            });
        },
    },
});

export default component;
